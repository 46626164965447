import {
    SEND_TRACKING_NUMBERS_REQUEST,
    SEND_TRACKING_NUMBERS_SUCCESS,
    SEND_TRACKING_NUMBERS_FAILURE,
    SEND_SHIPMENT_TRACKING_REQUEST,
    SEND_SHIPMENT_TRACKING_SUCCESS,
    SEND_SHIPMENT_TRACKING_FAIL,
  } from '../actions/trackingActions';


    

  
  const initialState = {
    loading: false,
    success: false,
    error: null,
  };
  
  const trackingReducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_TRACKING_NUMBERS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case SEND_TRACKING_NUMBERS_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
        };
      case SEND_TRACKING_NUMBERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export const shipmentTrackingReducer = (state = {}, action) => {
    switch (action.type) {
      case SEND_SHIPMENT_TRACKING_REQUEST:
        return { loading: true };
      case SEND_SHIPMENT_TRACKING_SUCCESS:
        return { loading: false, success: true, data: action.payload};
      case SEND_SHIPMENT_TRACKING_FAIL:
        return { loading: false, error2: action.payload.error };
      default:
        return state;
    }
  };
  
  export default trackingReducer;
  