import React from 'react';
import axios from 'axios';
import { Form, Button, Alert } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';

function ContactUs() {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      await axios.post('/api/contact', { name, email, message });
      setSuccess('Your message was successfully sent!');
    } catch (error) {
      setError('An error occurred while submitting the form');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FormContainer>
      <h1>Contact Us</h1>
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(event) => setName(event.target.value)} />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter your email" value={email} onChange={(event) => setEmail(event.target.value)} />
      </Form.Group>
      <Form.Group controlId="formMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows="3" placeholder="Enter your message" value={message} onChange={(event) => setMessage(event.target.value)} />
      </Form.Group>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" type="submit" disabled={submitting}>
        {submitting ? 'Submitting...' : 'Submit'}
      </Button>
    </Form>
    </FormContainer>
  );
}

export default ContactUs;