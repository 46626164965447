export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_CONFIRM_FAIL = 'USER_CONFIRM_FAIL'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_REGISTER_SCREEN_REQUEST = 'USER_REGISTER_SCREEN_REQUEST'
export const USER_REGISTER_SCREEN_SUCCESS = 'USER_REGISTER_SCREEN_SUCCESS'
export const USER_REGISTER_SCREEN_FAIL = 'USER_REGISTER_SCREEN_FAIL'

export const USER_CONFIRMATION_REQUEST = 'USER_CONFIRMATION_REQUEST'
export const USER_CONFIRMATION_SUCCESS = 'USER_CONFIRMATION_SUCCESS'
export const USER_CONFIRMATION_FAIL = 'USER_CONFIRMATION_FAIL'

export const USER_RESENDCONFIRM_REQUEST = 'USER_RESENDCONFIRM_REQUEST'
export const USER_RESENDCONFIRM_SUCCESS = 'USER_RESENDCONFIRM_SUCCESS'
export const USER_RESENDCONFIRM_FAIL = 'USER_RESENDCONFIRM_FAIL'

export const USER_FORGOTPW_REQUEST = 'USER_FORGOTPW_REQUEST'
export const USER_FORGOTPW_SUCCESS = 'USER_FORGOTPW_SUCCESS'
export const USER_FORGOTPW_FAIL = 'USER_FORGOTPW_FAIL'

export const USER_RESETPW_REQUEST = 'USER_RESETPW_REQUEST'
export const USER_RESETPW_SUCCESS = 'USER_RESETPW_SUCCESS'
export const USER_RESETPW_FAIL = 'USER_RESETPW_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'
