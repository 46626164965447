import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className='text-center py-3'>Copyright &copy; Lotustor
          
          
          </Col>
          

        </Row>
        <Row>
          <Col className='text-center py-3'>
          
          <Link to='/contact'>Contact Us</Link>
          </Col>
          

        </Row>

        
      </Container>
    </footer>
  )
}

export default Footer
