import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { resetUserPw } from '../actions/userActions'

const ResetPasswordScreen = () => {
  const {token} = useParams();
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordV, setNewPasswordV] = useState('')
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()

  const userResetpw = useSelector((state) => state.userResetpw)
  const { loading, success, error } = userResetpw

  const submitHandler = (e) => {
    e.preventDefault()
    if(newPassword === newPasswordV){
        dispatch(resetUserPw(token, newPassword))
    }else {
        setMessage("Passwords do not match.")
    }
    
  }

  return (
    <FormContainer>
      <h1>Reset Password</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>

        <Form.Group controlId='newPassword'>
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='newPasswordV'>
          <Form.Label>Verify New Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={newPasswordV}
            onChange={(e) => setNewPasswordV(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {message && <p>{message}</p>}
        
      {success && <h1>Password reset successful</h1>}

        <Button type='submit' variant='primary'>
          Reset Password
        </Button>
      </Form>

    </FormContainer>
  )
}

export default ResetPasswordScreen