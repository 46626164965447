import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productImageUploadReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userRegisterScreenReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userResendConfirmationReducer,
  userConfirmationReducer,
  userForgotPwReducer,
  userResetPwReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderShipReducer,
  orderListMyReducer,
  orderListReducer,
} from './reducers/orderReducers'
import{
  USPSShippingReducer,
  USPSAddressReducer
} from './reducers/shippingReducers'
import{
  supportMessageReducer
} from './reducers/supportMessageReducer'
import { shipmentTrackingReducer } from './reducers/trackingReducer';

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productImageUpdate: productImageUploadReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  uspsShippingRequest: USPSShippingReducer,
  uspsAddressRequest: USPSAddressReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userRegisterScreen:userRegisterScreenReducer,
  userConfirmation: userConfirmationReducer,
  userResendConfirmation: userResendConfirmationReducer,
  userForgotPw: userForgotPwReducer,
  userResetpw: userResetPwReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderShip: orderShipReducer,
  shipmentTracking: shipmentTrackingReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  supportMessage: supportMessageReducer,
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

  const selectedRateFromStorage = localStorage.getItem('selectedRate')
  ? JSON.parse(localStorage.getItem('selectedRate'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
    selectedRate: selectedRateFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
