import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { resendConfirmationEmail } from '../actions/userActions'

const ResendConfirmationScreen = ({location}) => {
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  

  const userResendConfirmation = useSelector((state) => state.userResendConfirmation)
  const { loading, success, error} = userResendConfirmation



  const redirect = location.search ? location.search.split('=')[1] : '/'
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(resendConfirmationEmail(email))
  }

  return (
    <FormContainer>
      <h1>Resend Confirmation Email</h1>
      {error && <Message variant='danger'>{error}</Message>}
      {success && <Message variant='success'>Email sent</Message>}
      {loading && <Loader />}
      <div></div>
      <h4>Did not receive an email?</h4>
      <p>No worries, enter the email associated with your account, and we will send a confirmation link to that email address.</p>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Account Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Send Email
        </Button>
      </Form>
      

      <Row className='py-3'>
        <Col>
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Back to login
          </Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default ResendConfirmationScreen