import { USPS_ADDRESS_FAIL, USPS_ADDRESS_REQUEST, USPS_ADDRESS_SUCCESS, USPS_SHIPPING_FAIL, USPS_SHIPPING_REQUEST, USPS_SHIPPING_SUCCESS } from "../constants/shippingConstants"

export const USPSShippingReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case USPS_SHIPPING_REQUEST:
        return { loading: true }
      case USPS_SHIPPING_SUCCESS:
        return { loading: false, ratesR: action.payload}
      case USPS_SHIPPING_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }

export const USPSAddressReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case USPS_ADDRESS_REQUEST:
        return { loading: true }
      case USPS_ADDRESS_SUCCESS:
        return { loading: false, shippingAddressRes: action.payload}
      case USPS_ADDRESS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }
  