import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { black, bold } from 'colors'

const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`/search/${keyword}`)
    } else {
      history.push('/')
    }
  }

  return (
    <Form  style={{height: 50, paddingTop: 10}} className="d-flex" onSubmit={submitHandler} inline>
      <Form.Control style={{border: '2px solid rgba(0, 0, 0, 0.05)' , borderTopLeftRadius: 25, borderBottomLeftRadius: 25 }}
        type='text'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder='Search Products...'
        className='ms-2'
      ></Form.Control>
      <Button style={{backgroundColor: 'lightgreen', border:'2px solid green' , borderTopRightRadius: 25, borderBottomRightRadius: 25, width: 25}} type='submit' variant='outline-success' className='ms'>
        <div style={{marginLeft: -20, marginTop:-5, fontSize: 10, fontWeight: 900, color: 'white' }}>Search</div>
      </Button>
    </Form>
  )
}

export default SearchBox
