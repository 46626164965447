// src/reducers/messageReducer.js
import * as actionTypes from '../constants/supportMessageConstants';

const initialState = {
  messages: [],
  error: null,
};

export const supportMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload,
        error: null,
      };
    case actionTypes.GET_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        messages: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

