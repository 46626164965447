import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import ReCAPTCHA from "react-google-recaptcha"


import { useRef } from 'react'

const LoginScreen = ({ location, history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [grecaptcha, setGrecaptcha] = useState('')
  function onChange(value) {
    console.log('Captcha value:', value)
    setGrecaptcha(value)
  }

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo, confirmationLink } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  useEffect(() => {
    if (error) {
      window.grecaptcha.reset()
    }
  }, [error])

  
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password, grecaptcha))
  }

  return (
    <FormContainer>
      <h1>Sign In</h1>
      
      {error && <Message variant='danger'>{error}</Message>}
      {confirmationLink ? <Row className='py-3'>
        <Col>
          <Link to={redirect ? `/resendconfirmation?redirect=${redirect}` : '/resendconfirmation'}>
            Resend Confirmation Email
          </Link>
        </Col>
      </Row> : <p></p>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type='email'
            placeholder='Enter email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
      <span></span>

        <Form.Group className="mb-3" controlId='password'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group className="mb-3" controlId='recaptcha'>
          <ReCAPTCHA controlId='grecaptcha'
   sitekey="6LeX2aQjAAAAAD9w5qaqWgsX0LJXlJZ7ccJTucPR"
    onChange={onChange}
  />
        </Form.Group>

        <Button type='submit' variant='primary'>
          Sign In
        </Button>
      </Form>

      <Row className='py-3'>
        <Col>
          New Customer?{' '}
          <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
            Register
          </Link>
        </Col>
      </Row>

      <Row className='py-3'>
        <Col>
          Forgot Password?{' '}
          <Link to={redirect ? `/forgotpw?redirect=${redirect}` : '/forgotpw'}>
            Forgot Password
          </Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default LoginScreen
