import axios from 'axios';

// Action types
export const SEND_TRACKING_NUMBERS_REQUEST = 'SEND_TRACKING_NUMBERS_REQUEST';
export const SEND_TRACKING_NUMBERS_SUCCESS = 'SEND_TRACKING_NUMBERS_SUCCESS';
export const SEND_TRACKING_NUMBERS_FAILURE = 'SEND_TRACKING_NUMBERS_FAILURE';
//Track Shippment action types
export const SEND_SHIPMENT_TRACKING_REQUEST = 'SEND_SHIPMENT_TRACKING_REQUEST';
export const SEND_SHIPMENT_TRACKING_SUCCESS = 'SEND_SHIPMENT_TRACKING_SUCCESS';
export const SEND_SHIPMENT_TRACKING_FAIL = 'SEND_SHIPMENT_TRACKING_FAIL';

// Action creators
export const sendTrackingNumbersRequest = () => ({
  type: SEND_TRACKING_NUMBERS_REQUEST,
});

export const sendTrackingNumbersSuccess = (response) => ({
  type: SEND_TRACKING_NUMBERS_SUCCESS,
  payload: response,
});

export const sendTrackingNumbersFailure = (error) => ({
  type: SEND_TRACKING_NUMBERS_FAILURE,
  payload: error,
});

// Async action to send tracking numbers
export const sendTrackingNumbers = (orderId, bigNumber, email) => async (dispatch, getState) => {
  dispatch(sendTrackingNumbersRequest());
  try {

    const {
        userLogin: { userInfo },
      } = getState()

    const config = {
        headers: {
          'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`
        },
      }
    const response = await axios.post('/api/orders/email/send', { orderId, bigNumber, email }, config);
    dispatch(sendTrackingNumbersSuccess(response.data));
  } catch (error) {
    dispatch(sendTrackingNumbersFailure(error.message));
  }
};

export const sendShipmentTracking = (trackingId, destinationCountry, language, zipcode) => async (dispatch, getState) => {
  if (
    !trackingId ||
    !language ||
    !destinationCountry ||
    !zipcode
  ) {
    return dispatch({
      type: SEND_SHIPMENT_TRACKING_FAIL,
      payload: 'Invalid shipment data',
    });
  }

  try {
    dispatch({ type: SEND_SHIPMENT_TRACKING_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState()

  const config = {
      headers: {
        'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
      },
    }

    const { data } = await axios.post('/api/orders/shipments/tracking', { trackingId, destinationCountry, language, zipcode }, config);
    localStorage.setItem('thisisinsideship', JSON.stringify(data))
    dispatch({
      type: SEND_SHIPMENT_TRACKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_SHIPMENT_TRACKING_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
