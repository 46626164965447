import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { registerSuccessScreen, resendConfirmationEmail } from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Alert from 'react-bootstrap/Alert';
import { Button, Form } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha"

const RegisterConfirmationScreen = ({ location}) => {
  const [show, setShow] = useState(false);
  const [showa, setShowa] = useState(false);
    const {token} = useParams();
    console.log(token);
    const dispatch = useDispatch()

    const userRegisterScreen =useSelector((state) => state.userRegisterScreen)
    const { loading, error, email} = userRegisterScreen

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const handleSendEmail = () => {
      
      if (grecaptcha == null){
        setShow(false)
        setShowa(true)
      }
      if (grecaptcha !== null ){
        dispatch(resendConfirmationEmail(email, grecaptcha))
        setShowa(false)
        setShow(true)
      }
      // setShow(true)
    }
    
    const [grecaptcha, setGrecaptcha] = useState(null)
    function onChange(value) {
      console.log('Captcha value:', value)
      setGrecaptcha(value)
    }
    console.log(grecaptcha)
    console.log(email)

    useEffect(() => {
      if (error) {
        window.grecaptcha.reset()
      }
    }, [error])
    
    useEffect(() => {
      dispatch(registerSuccessScreen(token))
    }, [dispatch, token]);

    

    

    return (
        <>
             {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <h1>Thanks For Signing Up!</h1>
          <p>Yay!! You have signed up for the Handshake store!</p>
          <div></div>
          <p>You should be receiving an email from us for you to confirm your account with us. After confirming you should be all set to login. ^___^</p>
          <div></div>
          <Alert show={showa} variant="danger">
        <Alert.Heading>PLEASE USE ReCAPTCHA!</Alert.Heading>
        <Button onClick={() => setShowa(false)} variant="outline-success">
            OK
          </Button>
        </Alert>
        
        <Alert show={show} variant="success">
        <Alert.Heading>EMAIL SENT!</Alert.Heading>
        <Button onClick={() => setShow (false)} variant="outline-success">
            OK
          </Button>
        
        </Alert>
        <div className="d-flex justify-content-end">

        

          
        </div>
        <div><Form.Group className="mb-3" controlId='recaptcha'>
          <ReCAPTCHA controlId='grecaptcha'
   sitekey="6LeX2aQjAAAAAD9w5qaqWgsX0LJXlJZ7ccJTucPR"
    onChange={onChange}
  />
        </Form.Group></div>
        
      
          <Link href='#' onClick={handleSendEmail}>
            Resend Confirmation Email
          </Link>
        </>
      )}
        </>
    )
}

export default RegisterConfirmationScreen