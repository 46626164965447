import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { confirmUser } from '../actions/userActions'
import Message from '../components/Message'
import Loader from '../components/Loader'

const ConfirmationScreen = () => {
    const {token} = useParams();
    const dispatch = useDispatch()

    const userConfirmation =useSelector((state) => state.userConfirmation)
    const { loading, error} = userConfirmation

    useEffect(() => {
      dispatch(confirmUser(token))
    }, [dispatch, token]);

    

    return (
        <>
             {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <h1>Thank you for confirming your email!</h1>
          <p>Great, now you can login!</p>
        </>
      )}
        </>
    )
}

export default ConfirmationScreen