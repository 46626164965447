import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import Meta from '../components/Meta'
import { listProducts } from '../actions/productActions'
// import InfiniteScroll from 'react-infinite-scroll-component'
// import { shuffle } from 'lodash'




const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList


  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber))
    
  }, [dispatch, keyword, pageNumber])

  return (
    <>
    <div><Meta />
      {!keyword ? (
        <ProductCarousel />
      ) : (
        <Link to='/' className='btn btn-light'>
          Go Back
        </Link>
      )}
      <h1>Latest Products</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
        
          <Row>
            {products.map((product) => (
              <Col key={product._id} xs={6} sm={6} md={6} lg={4} xl={3}>
                <Product product={{
                  ...product,
                  name: product.name.length > 25 ? product.name.substring(0, 40) + '.......' : product.name
                }} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={pages}
            page={page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}</div>
      
    </>
  )
}

export default HomeScreen

// import React, { useEffect, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Row, Col } from 'react-bootstrap';
// import Product from '../components/Product';
// import Message from '../components/Message';
// import Loader from '../components/Loader';
// import ProductCarousel from '../components/ProductCarousel';
// import Meta from '../components/Meta';
// import { listProducts } from '../actions/productActions';

// const HomeScreen = () => {
//   const dispatch = useDispatch();
//   const productsRef = useRef([]);
//   const loadingMore = useRef(false); // Track whether more products are being loaded

//   const productList = useSelector((state) => state.productList);
//   const { loading, error, products} = productList;

//   const observer = useRef(
//     new IntersectionObserver(
//       (entries) => {
//         const target = entries[0];
//         if (target.isIntersecting && !loadingMore.current) {
//           // Fetch more products when the last product is in view and no ongoing loading
//           loadingMore.current = true;
//           dispatch(listProducts(''));
//         }
//       },
//       { threshold: 1 } // Trigger when the target is fully in view
//     )
//   );

//   useEffect(() => {
//     // Observe the last product in the list
//     const targetProduct = productsRef.current[products.length - 1];
//     if (targetProduct) {
//       observer.current.observe(targetProduct);
//     }

//     // Cleanup the observer when the component unmounts
//     return () => observer.current.disconnect();
//   }, [products]);

//   useEffect(() => {
//     // Reset the loadingMore flag when new products are received
//     loadingMore.current = false;
//   }, [products]);

//   useEffect(() => {
//     dispatch(listProducts(''));
//   }, [dispatch]);

//   return (
//     <>
//       <div style={{ marginLeft: '140px' }}>
//         <Meta />
//         <ProductCarousel />
//         <h1>Latest Products</h1>
//         <Row>
//           {products.map((product, index) => (
//             <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
//               <Product product={product} />
//               {index === products.length - 1 && (
//                 <div ref={(ref) => (productsRef.current[index] = ref)} />
//               )}
//             </Col>
//           ))}
//         </Row>
//         {loading ? (
//           <Loader />
//         ) : error ? (
//           <Message variant="danger">{error}</Message>
//         ) : null}
//       </div>
//     </>
//   );
// };

// export default HomeScreen;