import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listProductDetails, updateProduct, uploadProductImage } from '../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id
  // let time = "false"
  const [time, setTime] = useState(false)
  const [name, setName] = useState('')
  const [purchaseLink, setPurchaseLink] = useState('')
  const [video, setVideo] = useState('')
  const [shippingMethod, setShippingMethod] = useState('')
  const [price, setPrice] = useState(0)
  const [colorsVariants, setColorsVariants] = useState([] || '');
  const [imageC, setImageC] = useState([])
  const [imageUploading, setImageUploading] = useState(false)
  const [imageB, setImageB] = useState([1,2])
  const [image, setImage] = useState('')
  const [brand, setBrand] = useState('')
  const [imageFolderName, setImageFolderName] = useState('Undefined')
  const [category, setCategory] = useState('')
  const [countInStock, setCountInStock] = useState(0)
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [uploading, setUploading] = useState(false)
  const dispatch = useDispatch()
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails
  const productImageUpdate = useSelector((state) => state.productImageUpdate)
  const {
    success: successProductImageUpdate,
    loading: loadingProductImageUpdate,
    error: errorProductProductImageUpdate,
  } = productImageUpdate
  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId))
      } else {
        setName(product.name)
        setPurchaseLink(product.purchaseLink)
        setShippingMethod(product.shippingMethod)
        setPrice(product.price)
        setImage(product.image)
        setImageC(product.imageC)
        setImageFolderName(product.imageFolderName)
        setImageB(product.imageB)        
        setColorsVariants(product.colorsVariants)        
        setVideo(product.video)
        setBrand(product.brand)
        setCategory(product.category)
        setCountInStock(product.countInStock)
        const blocksFromHtml = htmlToDraft(product.description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setDescription(editorState);
      }
    }
  }, [dispatch, history, productId, product, successUpdate])

  const uploadFileHandler = (e) => {
    
    setImageC(e.target.files)

    console.log(imageC)
  }

  const handleConfirmClose = () => {
    setShowUploadImageModal(false)
  };

  useEffect(() => {
    if (imageUploading === true) {
      setImageB(imageC);
      console.log(imageB);
  
      dispatch(
        uploadProductImage({
          _id: productId,
          imageFolderName,
          imageB,
        })
      );
      setImageUploading(false);

    }
    setImageUploading(false);
  }, [imageUploading, imageC, imageB, productId, imageFolderName, dispatch]);

  useEffect(() => {
    if (successProductImageUpdate) {
      setShowUploadImageModal(true);
    }
  }, [successProductImageUpdate]);

  if (time == true) {
    const descriptionHtml = draftToHtml(convertToRaw(description.getCurrentContent()));
    // setImageB(imageC)
    console.log(imageC)

    dispatch(
      
      updateProduct({
        _id: productId,
        name,
        purchaseLink,
        price,
        image,
        imageFolderName,
        imageB,
        colorsVariants,
        video,
        brand,
        purchaseLink,
        shippingMethod,
        category,
        description: descriptionHtml,
        countInStock,
      })
    
    )
    setTime(false)
  }

  const addColorsVariants = () => {
    setColorsVariants([...colorsVariants, { name: '', src: '' }]);
  };

  const removeColorsVariants = (index) => {
    const newColorsVariants = colorsVariants.filter((_, i) => i !== index);
    setColorsVariants(newColorsVariants);
  };

  const handleColorsVariantsChange = (index, key, value) => {
    const newColorsVariants = [...colorsVariants];
    newColorsVariants[index][key] = value;
    setColorsVariants(newColorsVariants);
  };

  // const addColorsVariants = () => {
  //   setColorsVariants([...colorsVariants, '']);
  //   console.log(colorsVariants)
  // };
  
  // const removeColorsVariants = (index) => {
  //   const newColorsVariants = colorsVariants.filter((_, i) => i !== index);
  //   setColorsVariants(newColorsVariants);
  // };
  
  // const handleColorsVariantsChange = (index, value) => {
  //   const newColorsVariants = [...colorsVariants];
  //   newColorsVariants[index] = value;
  //   setColorsVariants(newColorsVariants);
  // };
  
  const uploadImagesHandler = async () =>{
    
    const formData = new FormData()

    for (let i = 0; i < imageC.length; i++) {
      formData.append('files', imageC[i]);
    }
    console.log('4'+''+formData)
  
    setUploading(true)
    // formData.append('name', name); // Add the folder name to the form data
  
    try {
      
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    
  
      const { data } = await axios.post(`/api/upload/${imageFolderName}`, formData, config);
  
      setImageB(data)
      console.log(imageC)
      setUploading(false)
      setImageUploading(true)
    } catch (error) {
      console.error(error)
      setImageUploading(false)
    }

  }

  

  const submitHandler = async (e) => {
    e.preventDefault()

    
    // console.log(imageC)
    // // const file = e.target.files[0]
    // // console.log('2'+''+file)
    // const formData = new FormData()
    // console.log('3'+''+formData)

    if (imageC == undefined) {
    //   console.log('4'+''+formData)
  
    // setUploading(true)
  
    try {
    //   const config = {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   }
  
      
      console.log(imageC)
    //   setUploading(false)
      setTime(true)
    } catch (error) {
      console.error(error)
      // setUploading(false)
    }

    }

    if(imageC) {

      // for (let i = 0; i < imageC.length; i++) {
      //   formData.append('files', imageC[i]);
      // }
      // console.log('4'+''+formData)
    
      // setUploading(true)
      // // formData.append('name', name); // Add the folder name to the form data
    
      try {
        
        // const config = {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //   },
        // }
      
    
        // const { data } = await axios.post(`/api/upload/${imageFolderName}`, formData, config);
    
        // setImageB(data)
        console.log(imageC)
        console.log(imageB+' here now')
        // setUploading(false)
        setTime(true)
      } catch (error) {
        console.error(error)
        // setUploading(false)
      }

    }

    
    
  }
  

  return (
    <>
      <Link to='/admin/productlist' className='btn btn-light my-3'>
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name' className='pt-2'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type='name'
                placeholder='Enter name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='purchaseLink'className='pt-5'>
              <Form.Label>PurchaseLink</Form.Label>
              <Form.Control
                type='text'
                placeholder='Purchase Link'
                value={purchaseLink}
                onChange={(e) => setPurchaseLink(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='price' className='pt-5'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter price'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>
            
            <Form.Group controlId='shippingMethod' className='pt-5'>
              <Form.Label>Shipping Method</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter shipping method'
                value={shippingMethod}
                onChange={(e) => setShippingMethod(e.target.value)}
              ></Form.Control>
            </Form.Group>

            

            <Form.Group controlId='imageFolderName' className='pt-5'>
              <Form.Label>Image Gallery Folder Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter shipping method'
                value={imageFolderName}
                onChange={(e) => setImageFolderName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='image' className='pt-5'>
              <Form.Label>Image Gallery Files</Form.Label>
              <Form.Control
                placeholder='Enter image url'
                // value={imageC}
                // onChange={(e) => setImageC(e.target.value)}
              ></Form.Control>
              <Form.File
                type='file'
                id='image-file'
                label='Choose File'
                name='multi-files'
                // value={imageC}
                custom
                multiple
                onChange={uploadFileHandler}
              ></Form.File>
              {uploading && <Loader />}
              <Button variant='primary' onClick={uploadImagesHandler} className='mt-2'>
        Upload Files
      </Button>
            </Form.Group>

            <Form.Group controlId='image-main' className='pt-5'>
              <Form.Label>Image Main</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter image url'
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.File
                id='image-main-file'
                label='Choose File'
                custom
                multiple
                onChange={(e) => setImage( `/uploads\\${imageFolderName}\\${e.target.files[0].name}` )}

              ></Form.File>
            </Form.Group>

            <Form.Group controlId='video' className='pt-5'>
              <Form.Label>Video</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter video link'
                value={video}
                onChange={(e) => setVideo(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='brand' className='pt-5'>
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter brand'
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='countInStock' className='pt-5'>
              <Form.Label>Count In Stock</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter countInStock'
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='category' className='pt-5'>
              <Form.Label>Category</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter category'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='description' className='pt-5'>
               <Form.Label>Description</Form.Label>
               <Editor
                editorState={description}
                onEditorStateChange={(editorState) => setDescription(editorState)}
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
                }}
              />
            </Form.Group>

            <Form.Group controlId='colorVariants' className='pt-6'>
      <Form.Label>Color Variants</Form.Label>
      {colorsVariants.map((color, index) => (
        <div key={index} className='mb-3'>
          <Form.Control
            type='text'
            placeholder='Enter color name'
            value={color.name}
            onChange={(e) => handleColorsVariantsChange(index, 'name', e.target.value)}
            className='mb-2'
          />
          {/* <Form.Control
            type='text'
            placeholder='Enter color image src'
            value={color.src}
            onChange={(e) => handleColorsVariantsChange(index, 'src', e.target.value)}
          /> */}
          <Form.File
            id={`image-main-file-${index}`}
            label='Choose image src File'
            custom
            onChange={(e) => handleColorsVariantsChange(index, 'src', e.target.files[0] ? `/uploads\\${imageFolderName}\\${e.target.files[0].name}` : '')}
          />
          <Button
            variant='danger'
            onClick={() => removeColorsVariants(index)}
            className='mt-2 pt-2'
          >
            Remove
          </Button>
        </div>
      ))}
      <div className='pt-2'><Button variant='primary' onClick={addColorsVariants} className='mt-2 pt-2'>
        Add Color
      </Button></div>
      
    </Form.Group>

            {/* <Form.Group controlId='colorVariants'>
            <Form.Label>Color Variants</Form.Label>
            {colorsVariants.map((color, index) => (
              <div key={index} className='mb-3'>
                <Form.Control
                  type='text'
                  placeholder='Enter color'
                  value={color}
                  onChange={(e) => handleColorsVariantsChange(index, e.target.value)}
                />
                <Button
                  variant='danger'
                  onClick={() => removeColorsVariants(index)}
                  className='mt-2'
                >
                  Remove
                </Button>
              </div>
            ))}
            <Button variant='primary' onClick={addColorsVariants} className='mt-2'>
              Add Color
            </Button>
          </Form.Group> */}
          {console.log(colorsVariants)}
            <div className=' pt-5'><Button type='submit' variant='primary' className=' pt-2'>
              Update Product
            </Button></div>
            
          </Form>
        )}
      </FormContainer>

      <Modal show={showUploadImageModal} onHide={handleConfirmClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Close</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {successProductImageUpdate && <Message variant='success'>{successProductImageUpdate}</Message>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleConfirmClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ProductEditScreen

// import axios from 'axios';
// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Form, Button } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, convertToRaw, ContentState } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import Message from '../components/Message';
// import Loader from '../components/Loader';
// import FormContainer from '../components/FormContainer';
// import { listProductDetails, updateProduct } from '../actions/productActions';
// import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';

// const ProductEditScreen = ({ match, history }) => {
//   const productId = match.params.id;

//   const [name, setName] = useState('');
//   const [purchaseLink, setPurchaseLink] = useState('');
//   const [price, setPrice] = useState(0);
//   const [image, setImage] = useState('');
//   const [brand, setBrand] = useState('');
//   const [category, setCategory] = useState('');
//   const [countInStock, setCountInStock] = useState(0);
//   const [description, setDescription] = useState(EditorState.createEmpty());
//   const [uploading, setUploading] = useState(false);

//   const dispatch = useDispatch();

//   const productDetails = useSelector((state) => state.productDetails);
//   const { loading, error, product } = productDetails;

//   const productUpdate = useSelector((state) => state.productUpdate);
//   const {
//     loading: loadingUpdate,
//     error: errorUpdate,
//     success: successUpdate,
//   } = productUpdate;

//   useEffect(() => {
//     if (successUpdate) {
//       dispatch({ type: PRODUCT_UPDATE_RESET });
//       history.push('/admin/productlist');
//     } else {
//       if (!product.name || product._id !== productId) {
//         dispatch(listProductDetails(productId));
//       } else {
//         setName(product.name);
//         setPurchaseLink(product.purchaseLink);
//         setPrice(product.price);
//         setImage(product.image);
//         setBrand(product.brand);
//         setCategory(product.category);
//         setCountInStock(product.countInStock);
        
//         const blocksFromHtml = htmlToDraft(product.description);
//         const { contentBlocks, entityMap } = blocksFromHtml;
//         const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
//         const editorState = EditorState.createWithContent(contentState);
//         setDescription(editorState);
//       }
//     }
//   }, [dispatch, history, productId, product, successUpdate]);

//   const uploadFileHandler = async (e) => {
//     const file = e.target.files[0];
//     const formData = new FormData();
//     formData.append('image', file);
//     setUploading(true);

//     try {
//       const config = {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       };
//       const { data } = await axios.post('/api/upload', formData, config);
//       setImage(data);
//       setUploading(false);
//     } catch (error) {
//       console.error(error);
//       setUploading(false);
//     }
//   };

//   const submitHandler = (e) => {
//     e.preventDefault();
//     const descriptionHtml = draftToHtml(convertToRaw(description.getCurrentContent()));
//     dispatch(
//       updateProduct({
//         _id: productId,
//         name,
//         purchaseLink,
//         price,
//         image,
//         brand,
//         category,
//         description: descriptionHtml,
//         countInStock,
//       })
//     );
//   };

//   return (
//     <>
//       <Link to='/admin/productlist' className='btn btn-light my-3'>
//         Go Back
//       </Link>
//       <FormContainer>
//         <h1>Edit Product</h1>
//         {loadingUpdate && <Loader />}
//         {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
//         {loading ? (
//           <Loader />
//         ) : error ? (
//           <Message variant='danger'>{error}</Message>
//         ) : (
//           <Form onSubmit={submitHandler}>
//             <Form.Group controlId='name'>
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type='text'
//                 placeholder='Enter name'
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//               ></Form.Control>
//             </Form.Group>

//             <Form.Group controlId='purchaseLink'>
//               <Form.Label>Purchase Link</Form.Label>
//               <Form.Control
//                 type='text'
//                 placeholder='Enter purchase link'
//                 value={purchaseLink}
//                 onChange={(e) => setPurchaseLink(e.target.value)}
//               ></Form.Control>
//             </Form.Group>

//             <Form.Group controlId='price'>
//               <Form.Label>Price</Form.Label>
//               <Form.Control
//                 type='number'
//                 placeholder='Enter price'
//                 value={price}
//                 onChange={(e) => setPrice(e.target.value)}
//               ></Form.Control>
//             </Form.Group>

//             <Form.Group controlId='image'>
//               <Form.Label>Image</Form.Label>
//               <Form.Control
//                 type='text'
//                 placeholder='Enter image url'
//                 value={image}
//                 onChange={(e) => setImage(e.target.value)}
//               ></Form.Control>
//               <Form.File
//                 id='image-file'
//                 label='Choose File'
//                 custom
//                 onChange={uploadFileHandler}
//               ></Form.File>
//               {uploading && <Loader />}
//             </Form.Group>

//             <Form.Group controlId='brand'>
//               <Form.Label>Brand</Form.Label>
//               <Form.Control
//                 type='text'
//                 placeholder='Enter brand'
//                 value={brand}
//                 onChange={(e) => setBrand(e.target.value)}
//               ></Form.Control>
//             </Form.Group>

//             <Form.Group controlId='countInStock'>
//               <Form.Label>Count In Stock</Form.Label>
//               <Form.Control
//                 type='number'
//                 placeholder='Enter count in stock'
//                 value={countInStock}
//                 onChange={(e) => setCountInStock(e.target.value)}
//               ></Form.Control>
//             </Form.Group>

//             <Form.Group controlId='category'>
//               <Form.Label>Category</Form.Label>
//               <Form.Control
//                 type='text'
//                 placeholder='Enter category'
//                 value={category}
//                 onChange={(e) => setCategory(e.target.value)}
//               ></Form.Control>
//             </Form.Group>

//             <Form.Group controlId='description'>
//               <Form.Label>Description</Form.Label>
//               <Editor
//                 editorState={description}
//                 onEditorStateChange={(editorState) => setDescription(editorState)}
//                 toolbar={{
//                   options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history'],
//                 }}
//               />
//             </Form.Group>

//             <Button type='submit' variant='primary'>
//               Update
//             </Button>
//           </Form>
//         )}
//       </FormContainer>
//     </>
//   );
// };

// export default ProductEditScreen;

