// import React, { useEffect } from 'react';
// import { LinkContainer } from 'react-router-bootstrap';
// import { Table, Button, Row, Col } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import Message from '../components/Message';
// import Loader from '../components/Loader';
// import Paginate from '../components/Paginate';
// import {
//   listProducts,
//   deleteProduct,
//   createProduct,
// } from '../actions/productActions';
// import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

// const ProductListScreen = ({ history, match }) => {
//   const dispatch = useDispatch();
//   const pageNumber = match.params.pageNumber || 1;
  
//   const productList = useSelector((state) => state.productList);
//   const { loading, error, products, page, pages } = productList;

//   const productDelete = useSelector((state) => state.productDelete);
//   const {
//     loading: loadingDelete,
//     error: errorDelete,
//     success: successDelete,
//   } = productDelete;

//   const productCreate = useSelector((state) => state.productCreate);
//   const {
//     loading: loadingCreate,
//     error: errorCreate,
//     success: successCreate,
//     product: createdProduct,
//   } = productCreate;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   useEffect(() => {
//     dispatch({ type: PRODUCT_CREATE_RESET });

//     if (!userInfo || !userInfo.isAdmin) {
//       history.push('/login');
//     }

//     if (successCreate) {
//       history.push(`/admin/product/${createdProduct._id}/edit`);
//     } else {
//       const adminCheck = userInfo.token
//       dispatch(listProducts('', 'name', 'asc', adminCheck));
//     }
//   }, [
//     dispatch,
//     history,
//     userInfo,
//     successDelete,
//     successCreate,
//     createdProduct,
//     pageNumber,
//   ]);

//   const deleteHandler = (id) => {
//     if (window.confirm('Are you sure?')) {
//       dispatch(deleteProduct(id));
//     }
//   };

//   const createProductHandler = () => {
//     dispatch(createProduct());
//   };

//   return (
//     <>
//       <Row className='align-items-center'>
//         <Col>
//           <h1>Products</h1>
//         </Col>
//         <Col className='text-right'>
//           <Button className='my-3' onClick={createProductHandler}>
//             <i className='fas fa-plus'></i> Create Product
//           </Button>
//         </Col>
//       </Row>
//       {loadingDelete && <Loader />}
//       {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
//       {loadingCreate && <Loader />}
//       {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
//       {loading ? (
//         <Loader />
//       ) : error ? (
//         <Message variant='danger'>{error}</Message>
//       ) : (
//         <>
//           <Table striped bordered hover responsive className='table-sm'>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>
//                   NAME
//                 </th>
//                 <th>PURCHASE LINK</th>
//                 <th>PRICE</th>
//                 <th>CATEGORY</th>
//                 <th>BRAND</th>
//                 <th></th>
//               </tr>
//             </thead>
//             <tbody>
//               {products.map((product) => (
//                 <tr key={product._id}>
//                   <td>{product._id}</td>
//                   <td>{product.name}</td>
//                   <td>{product.purchaseLink}</td>
//                   <td>${product.price}</td>
//                   <td>{product.category}</td>
//                   <td>{product.brand}</td>
//                   <td>
//                     <LinkContainer to={`/admin/product/${product._id}/edit`}>
//                       <Button variant='light' className='btn-sm'>
//                         <i className='fas fa-edit'></i>
//                       </Button>
//                     </LinkContainer>
//                     <Button
//                       variant='danger'
//                       className='btn-sm'
//                       onClick={() => deleteHandler(product._id)}
//                     >
//                       <i className='fas fa-trash'></i>
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//           <Paginate pages={pages} page={page} isAdmin={true} />
//         </>
//       )}
//     </>
//   );
// };

// export default ProductListScreen;




import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { Form,Table, Button, Row, Col, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import {
  listProducts,
  deleteProduct,
  createProduct,
} from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

const ProductListScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;
  const [keyword, setKeyword] = useState('');

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login');
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listProducts(keyword, 'name', 'asc')); // Pass keyword to listProducts
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    keyword, // Add keyword to dependencies
  ]);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteProduct(id));
    }
  };

  const createProductHandler = () => {
    dispatch(createProduct());
  };

  return (
    <>
      <Row className='align-items-center'>
        <Col>
          <h1>Products</h1>
        </Col>
        <Col className='text-right'>
          <Button className='my-3' onClick={createProductHandler}>
            <i className='fas fa-plus'></i> Create Product
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
      
      {/* Integrate SearchBox component */}
      <Form  inline>
      <Form.Control
        type='text'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder='Search Products...'
        className='mr-sm-2'
      ></Form.Control>
    </Form>
      
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ID</th>
                <th>IMAGE</th>
                <th>NAME</th>
                <th>PURCHASE LINK</th>
                <th>PRICE</th>
                <th>CATEGORY</th>
                <th>BRAND</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td><Image src={product.image} alt={product.name} fluid rounded /></td>
                  <td><Link to={`/product/${product._id}`}>{product.name}</Link></td>
                  <td>
                    <Button
                      variant='primary'
                      href={product.purchaseLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Purchase Link
                    </Button>
                  </td>
                  <td>${product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => deleteHandler(product._id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate pages={pages} page={page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default ProductListScreen;