import React, { useEffect, useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../actions/cartActions'
import { getUSPSAddress } from '../actions/shippingActions'


const ShippingScreen = ({ history, props }) => {
 
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const [toFirstName, setToFirstName] = useState(shippingAddress.toFirstName)
  const [toLastName, setToLastName] = useState(shippingAddress.toLastName)
  const [address1, setAddress1] = useState(shippingAddress.address1)
  const [address2, setAddress2] = useState(shippingAddress.address2)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [state, setState] = useState(shippingAddress.state)
  const [country, setCountry] = useState(shippingAddress.country)
  const [addresses, setAddresses] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [verified, setVerified] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  }

  

  const dispatch = useDispatch()

  const uspsAddressRequest = useSelector((state) => state.uspsAddressRequest)
  const { shippingAddressRes } = uspsAddressRequest
// if (shippingAddressRes) {
//       setAddresses(shippingAddressRes.AddressValidateResponse.Address);
//       console.log(addresses)
      // setShowModal(true);
//     }

    function setAddress(shippingAddressRes)  {
        setAddresses(shippingAddressRes.AddressValidateResponse.Address)
        
      }
      console.log(addresses)

    useEffect(() => {
      if(shippingAddressRes) {setAddress(shippingAddressRes)}
    }, [shippingAddressRes]);

    
  const handleSubmit = () => {
    // // Save selected address to local storage
    // localStorage.setItem('shippingAddress', JSON.stringify(selectedAddress));
    setAddress1(selectedAddress.Address2)
    setAddress2(selectedAddress.Address1)
    setCity(selectedAddress.City)
    console.log(selectedAddress.City)
    setState(selectedAddress.State)
    setPostalCode(selectedAddress.Zip5)
    setVerified(true)
    // dispatch(saveShippingAddress({ address1, address2, city, postalCode, state, country }))
    setShowModal(false);
    // setVerified(true)

    // history.push(`/shippingrate`)
    // Update the shipping address in the parent component
    // props.onSelect(selectedAddress);
  }
  console.log(city)
  useEffect(() => {
    if (verified){
      dispatch(saveShippingAddress({ address1, address2, city, postalCode, state, country }))
      history.push(`/payment`)
    }
  }, [verified]);
 

  const submitHandler = (e) => {
    e.preventDefault()
    console.log(address1)
    dispatch(getUSPSAddress( address1, address2, city, postalCode, state, country))
    setShowModal(true);

    
    
    
    // dispatch(saveShippingAddress({ address1, address2, city, postalCode, state, country }))
    // history.push(`/shippingrate`)

  }
  const handleSelect = (addresses) => {
    setSelectedAddress(addresses);
    console.log(selectedAddress)
  }


  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Shipping</h1>
      <Form onSubmit={submitHandler}>

        <Form.Group controlId='toFirstName'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='First Name'
            value={toFirstName}
            required
            // onChange={(e) => setAddress1(e.target.value)}
          ></Form.Control>
        </Form.Group>
        
        <Form.Group controlId='toLastName'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Last Name'
            value={toLastName}
            required
            // onChange={(e) => setAddress1(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='address1'>
          <Form.Label>Address 1</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter address'
            value={address1}
            required
            onChange={(e) => setAddress1(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='address2'>
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            type='text'
            placeholder='apt, suite, etc...'
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='city'>
          <Form.Label>City</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter city'
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='state'>
          <Form.Label>State</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter state'
            value={state}
            required
            onChange={(e) => setState(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='postalCode'>
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter postal code'
            value={postalCode}
            required
            onChange={(e) => setPostalCode(e.target.value)}
          ></Form.Control>
        </Form.Group>

        

        <Form.Group controlId='country'>
          <Form.Label>Country</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter country'
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Continue
        </Button>
        {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Shipping Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addresses.map(address => (
            <div key={address.Zip5}>
              <input
                type="radio"
                name="shipping-address"
                value={address.Zip5}
                onChange={() => handleSelect(address.Address)}
              />
              {addresses.Address2}, {addresses.City}, {addresses.State} {addresses.Zip}
            </div>
          ))}
          </Modal.Body>
          </Modal> */}
          <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Select Shipping Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {addresses && (
        <div key={addresses.Zip5}>
        <input
        type="radio"
        name="shipping-address"
        value={addresses.Zip5}
        onChange={() => handleSelect(addresses)}
        />
        <div></div>
        {addresses.Address2}, {addresses.Address1}, {addresses.City}, {addresses.State}, {addresses.Zip5}
        </div>
        )}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={!selectedAddress}>
        Save
        </Button>
        </Modal.Footer>
        </Modal>
      </Form>
    </FormContainer>
    
  )
}

export default ShippingScreen
