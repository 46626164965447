import axios from "axios"
import { USPS_ADDRESS_FAIL, USPS_ADDRESS_REQUEST, USPS_ADDRESS_SUCCESS, USPS_SHIPPING_FAIL, USPS_SHIPPING_REQUEST, USPS_SHIPPING_SUCCESS } from "../constants/shippingConstants"

export const getUSPSShipping = (postalCode) => async (dispatch) => {
    try {
      dispatch({ type: USPS_SHIPPING_REQUEST})
  
      const { data } = await axios.get(`/api/shipping/${postalCode}`)
  
      dispatch({
        type: USPS_SHIPPING_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USPS_SHIPPING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }


export const getUSPSAddress = (address1, address2, city, postalCode, state, country) => async (dispatch) => {
    try {
      dispatch({ type: USPS_ADDRESS_REQUEST})

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
  
      const { data } = await axios.post(`/api/shipping/shippingaddress`, {address1, address2, city, postalCode, state, country}, config)
  
      dispatch({
        type: USPS_ADDRESS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USPS_ADDRESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }