// src/actions/messageActions.js
import axios from 'axios';
import * as actionTypes from '../constants/supportMessageConstants';

const API_URL = 'http://localhost:5000/api/messages';

export const sendMessageSuccess = () => ({
  type: actionTypes.SEND_MESSAGE_SUCCESS,
});

export const sendMessageFailure = error => ({
  type: actionTypes.SEND_MESSAGE_FAILURE,
  payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
});

export const getMessagesSuccess = messages => ({
  type: actionTypes.GET_MESSAGES_SUCCESS,
  payload: messages,
});

export const getMessagesFailure = error => ({
  type: actionTypes.GET_MESSAGES_FAILURE,
  payload: error,
});

export const sendMessage = (sender, recipient, content, grecaptcha) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

  const config = {
      headers: {
        'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
      },
    }

    await axios.post('/api/messages/send', { sender, recipient, content, grecaptcha }, config);
    dispatch(sendMessageSuccess());
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    // dispatch(sendMessageFailure(error.message));
    if (message === 'Please fill in all feilds including captcha :)'){
      dispatch(sendMessageFailure(error))
   }
     if (message === 'Please use the captcha below :)'){
      dispatch(sendMessageFailure(error))
   }
  }
};

export const getMessages = (user) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

  const config = {
      headers: {
        'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`
      },
    }

    const response = await axios.get(`/api/messages/get/${user}`, config);
    dispatch(getMessagesSuccess(response.data));
  } catch (error) {
    dispatch(getMessagesFailure(error.message));
  }
};
