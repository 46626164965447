// import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
// import { Row, Col, Image, ListGroup, Card, Button, Form, Container } from 'react-bootstrap'
// import Rating from '../components/Rating'
// import Message from '../components/Message'
// import Loader from '../components/Loader'
// import Meta from '../components/Meta'
// import {
//   listProductDetails,
//   createProductReview,
// } from '../actions/productActions'
// import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'

// const ProductScreen = ({ history, match }) => {
//   const [showComponent, setShowComponent] = useState(false);
//   const [qty, setQty] = useState(1)
//   const [rating, setRating] = useState(0)
//   const [comment, setComment] = useState('')
//  const [selectedImage, setSelectedImage] = useState(null);
//  const [productImages, setProductImages] = useState([]);
//   const dispatch = useDispatch()
  

//   const productDetails = useSelector((state) => state.productDetails)
//   const { loading, error, product } = productDetails

//   const userLogin = useSelector((state) => state.userLogin)
//   const { userInfo } = userLogin
 


//   function setimg () {
//     //1
//     setProductImages(product.imageB)
//     setSelectedImage(productImages[0]) 
    
//   }
//   useEffect(() => {
//     if (productImages[0] = setSelectedImage) {
//       setSelectedImage(null);
   
//     setProductImagesOnLoad();
      
      
//     }
// setShowComponent(true); 
    
//   }, [product.imageB]);
//   useEffect(() => {   
//     if (product.imageB) {
//       // setSelectedImage(null);
//       setimg(product.imageB)
      
//     }
//   }, [product.imageB])

//   const setProductImagesOnLoad = () => {


//     if (product.imageB) {
//       setSelectedImage(null);
//       setProductImages(product.imageB);
//       setSelectedImage(null);
//       setSelectedImage(product.imageB[0]);
//     }
    
//   };

//   useEffect(() => {
//       // setSelectedImage(product.imageB[0]);


//     setSelectedImage(null);
   
//     window.scrollTo(0, 0);


//       dispatch(
     
//         listProductDetails(match.params.id)

//       )

//       setProductImagesOnLoad();
//   }, [dispatch, match.params.id])
  
//   const productReviewCreate = useSelector((state) => state.productReviewCreate)
//   const {
//     success: successProductReview,
//     loading: loadingProductReview,
//     error: errorProductReview,
//   } = productReviewCreate

//   useEffect(() => {
//     setSelectedImage(null);
//     if (successProductReview) {
    
//       setRating(0)
//       setComment('')

//     }
//     if (!product._id || product._id !== match.params.id) {
//       dispatch(listProductDetails(match.params.id))
//       dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
//     }
//   }, [dispatch, match, successProductReview, product._id])



//   const addToCartHandler = () => {
//     history.push(`/cart/${match.params.id}?qty=${qty}`)
//   }

//   const submitHandler = (e) => {
//     e.preventDefault()
//     dispatch(
//       createProductReview(match.params.id, {
//         rating,
//         comment,
//       })
//     )
//   }
//   const handleHover = (image) => {
//     setSelectedImage(image);
//   };

 
//   return showComponent ? (
//     <>
//       <Link  className='btn btn-light my-3' to='/'>
//         Go Back
//       </Link>
//       {loading ? (
//         <Loader style={{marginLeft:"140px"}}/>
//       ) : error ? (
//         <Message style={{marginLeft:"140px"}} variant='danger'>{error}</Message>
//       ) : (
//         <>
//         <div style={{marginLeft:"140px"}}>
//                 <Meta title={product.name} />

//                 <Row>
//                   <Col md={6}>
//                   <Container>
//             <Row>
//               <Col >
              

//             {selectedImage && (
//               <Image src={selectedImage.src} alt={selectedImage.alt} fluid />
//             )}
//             </Col>
//             <div></div>
            
//             </Row>
//           </Container>
                  
//                     <Row xs={12} md={6}>
            
//             {productImages.map((image, index) => (
//               <Image
//                 key={[index]}
//                 src={image.src}
//                 thumbnail
//                 className="mb-2"
//                 onMouseOver={() => handleHover(image)}
//               />
//             ))}
//             </Row>
//                   </Col>
                  
//                   <Col  md={3}>
//                     <Card>
//                       <ListGroup variant='flush'>
//                         <ListGroup.Item>
//                           <Row>
//                             <Col>Price:</Col>
//                             <Col>
//                               <strong>${product.price}</strong>
//                             </Col>
//                           </Row>
//                         </ListGroup.Item>

//                         <ListGroup.Item>
//                           <Row>
//                             <Col>Shipping:</Col>
//                             <Col>
//                               <h5>Free</h5>
//                             </Col>
//                           </Row>
//                         </ListGroup.Item>

//                         <ListGroup.Item>
//                           <Row>
//                             <Col>Status:</Col>
//                             <Col>
//                               {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
//                             </Col>
//                           </Row>
//                         </ListGroup.Item>

//                         {product.countInStock > 0 && (
//                           <ListGroup.Item>
//                             <Row>
//                               <Col>Qty</Col>
//                               <Col>
//                                 <Form.Control
//                                   as='select'
//                                   value={qty}
//                                   onChange={(e) => setQty(e.target.value)}
//                                 >
//                                   {[...Array(product.countInStock).keys()].map(
//                                     (x) => (
//                                       <option key={x + 1} value={x + 1}>
//                                         {x + 1}
//                                       </option>
//                                     )
//                                   )}
//                                 </Form.Control>
//                               </Col>
//                             </Row>
//                           </ListGroup.Item>
//                         )}

//                         <ListGroup.Item>
//                           <Button
//                             onClick={addToCartHandler}
//                             className='btn-block'
//                             type='button'
//                             disabled={product.countInStock === 0}
//                           >
//                             Add To Cart
//                           </Button>
//                         </ListGroup.Item>
//                       </ListGroup>
//                     </Card>
//                   </Col>
//                   <Col md={6}>
//                     <ListGroup variant='flush'>
//                       <ListGroup.Item>
//                         <h3>{product.name}</h3>
//                       </ListGroup.Item>
//                       <ListGroup.Item>
//                         <Rating
//                           value={product.rating}
//                           text={`${product.numReviews} reviews`}
//                         />
//                       </ListGroup.Item>
//                       <ListGroup.Item>Price: ${product.price}</ListGroup.Item>
//                       <ListGroup.Item>
//                         Description: {product.description}
//                       </ListGroup.Item>
//                     </ListGroup>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col md={9}>
//                     <h2>Reviews</h2>
//                     {product.reviews.length === 0 && <Message>No Reviews</Message>}
//                     <ListGroup variant='flush'>
//                       {product.reviews.map((review) => (
//                         <ListGroup.Item key={review._id}>
//                           <strong>{review.name}</strong>
//                           <Rating value={review.rating} />
//                           <p>{review.createdAt.substring(0, 10)}</p>
//                           <p>{review.comment}</p>
//                         </ListGroup.Item>
//                       ))}
//                       <ListGroup.Item>
//                         <h2>Write a Customer Review</h2>
//                         {successProductReview && (
//                           <Message variant='success'>
//                             Review submitted successfully
//                           </Message>
//                         )}
//                         {loadingProductReview && <Loader />}
//                         {errorProductReview && (
//                           <Message variant='danger'>{errorProductReview}</Message>
//                         )}
//                         {userInfo ? (
//                           <Form onSubmit={submitHandler}>
//                             <Form.Group controlId='rating'>
//                               <Form.Label>Rating</Form.Label>
//                               <Form.Control
//                                 as='select'
//                                 value={rating}
//                                 onChange={(e) => setRating(e.target.value)}
//                               >
//                                 <option value=''>Select...</option>
//                                 <option value='1'>1 - Poor</option>
//                                 <option value='2'>2 - Fair</option>
//                                 <option value='3'>3 - Good</option>
//                                 <option value='4'>4 - Very Good</option>
//                                 <option value='5'>5 - Excellent</option>
//                               </Form.Control>
//                             </Form.Group>
//                             <Form.Group controlId='comment'>
//                               <Form.Label>Comment</Form.Label>
//                               <Form.Control
//                                 as='textarea'
//                                 row='3'
//                                 value={comment}
//                                 onChange={(e) => setComment(e.target.value)}
//                               ></Form.Control>
//                             </Form.Group>
//                             <Button
//                               disabled={loadingProductReview}
//                               type='submit'
//                               variant='primary'
//                             >
//                               Submit
//                             </Button>
//                           </Form>
//                         ) : (
//                           <Message>
//                             Please <Link to='/login'>sign in</Link> to write a review{' '}
//                           </Message>
//                         )}
//                       </ListGroup.Item>
//                     </ListGroup>
//                   </Col>
//                 </Row>
//           </div>
      
//         </>
//       )}
//     </>
//   ) : null;
// }
  

// export default ProductScreen

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, ListGroup, Card, Button, Form, Container, Modal, } from 'react-bootstrap';import { Editor, EditorState, convertFromRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Rating from '../components/Rating';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Meta from '../components/Meta';

import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions';
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants';
import { isEmpty, isEqual } from 'lodash';
const colors = [];

const ProductScreen = ({ history, match }) => {
  const [welcome, setWelcome] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [colorsVariantClicked, setColorsVariantClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [descriptionState, setDescriptionState] = useState(() => EditorState.createEmpty());
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [productMedia, setProductMedia] = useState([]);
  const dispatch = useDispatch();


  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;  

  const [selectedColorsVariants, setSelectedColorsVariants] = useState([]);


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;



  const productReviewCreate = useSelector((state) => state.productReviewCreate)
    const {
      success: successProductReview,
      loading: loadingProductReview,
      error: errorProductReview,
    } = productReviewCreate


    const handleSelectColorVariant  = (colorName) => {
      const selectedColor = product.colorsVariants.find(color => color.name === colorName);
      if (selectedColor && colorsVariantClicked ) {
        console.log("fr",selectedColor.src)
        setSelectedMedia(selectedColor.src);
        setSelectedColorsVariants(selectedColor.name);
        setSelectedMedia(selectedColor.src);
        console.log(selectedColor.src)
      } else {
        console.log(`Color variant with name ${colorName} not found`);
      }
    };

    

    
   
  useEffect(() => {
    

    const fetchData = async () => {
      try {
        await dispatch(listProductDetails(match.params.id));
        console.log('Product details fetched successfully.');
        console.log(product.colorsVariants)
        
        if(product.colorsVariants) {
          const names = product.colorsVariants.map(variant => variant.name);
          setSelectedColorsVariants(names[0])
        }
  
        // After fetching details, set product media
        setProductMediaOnLoad();
        if (product.colorsVariants.length > 0) {
          handleSelectColorVariant(product.colorsVariants[0].name);
        }
        // setSelectedColorsVariants(product.colorsVariants[0].name)
        if ( isEmpty(product.colorsVariants)){
          setSelectedColorsVariants('N/A')
        }
        console.log('nothing to show? ', product.colorsVariants);

        console.log('Product media set up:', productMedia);
        if (product.description) {
          const blocksFromHtml = htmlToDraft(product.description);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          setDescriptionState(EditorState.createWithContent(contentState));
        }
        
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
     
    };
    


    fetchData();
  window.scrollTo(0, 0);
   
    if (successProductReview) {
    
      setRating(0)
      setComment('')

    }
    
    

    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
    if(welcome){
      setShowComponent(true)
    }
    
    return () => {
      // Set selectedMedia to null when the component unmounts
      setSelectedMedia([null]);
      setProductMedia([])
    };
    
  }, [dispatch, match.params.id,match, successProductReview, product._id, welcome, showComponent])

 

  const setProductMediaOnLoad = () => {
    const newProductMedia = [];

    if (product.imageB && product.imageB.length > 0) {
      newProductMedia.push(...product.imageB);
      setSelectedMedia(product.image);
      
      
    }


    if (product.video) {
      // function reconstructURL(data) {
      //   let url = "";
      
      //   // Iterate over the keys in the object and concatenate the corresponding characters
      //   for (const key in data[0]) {
      //     url += data[0][key];
      //   }
     
      //   return url;
      // }
      
      // // Call the function and log the reconstructed URL
      // const reconstructedURL = reconstructURL(product.video);
  
      console.log(product.video)
      newProductMedia.push({ type: 'video', src: product.video });
      setSelectedMedia(product.image);
      
      
      
    };

    setProductMedia(newProductMedia);
    setWelcome(true)
    }

   

  

  const handleMediaClick = (media) => {
    if (media.type === 'video') {window.scrollTo(0, 0);}
    
    setSelectedMedia(media);
    console.log('er', selectedMedia)
  };

  const handleHover = (media) => {
        setSelectedMedia(media);
      };
  const handleMouseOver = () => {
        setIsHovered(true);
      };
    
  const handleMouseOut = () => {
        setIsHovered(false);
      };
    

  const addToCartHandler = () => {
    console.log(selectedColorsVariants)
    history.push(`/cart/${match.params.id}?qty=${qty}&selectedColorsVariants=${selectedColorsVariants}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createProductReview(match.params.id, { rating, comment }));
  };
  const isMediaObject = typeof selectedMedia === 'object' && selectedMedia !== null;
 
  // function showComponentn() {
  //   if(welcome){
  //   setShowComponent(true)
  // }
  // }

  return showComponent ? (
    <>
      <Link className='btn btn-light my-1' to='/'>
        Go Back
      </Link>
      {loading ? (
        <Loader  />
      ) : error ? (
        <Message  variant='danger'>
          {error}
        </Message>
      ) : (
        <>
          <div >
            <Meta title={product.name} />

            <Row>
              <Col md={6}>
              <Container>
      <Row>
        <Col>
          {isMediaObject && selectedMedia.type === 'video' ? (
            <div>
              <iframe
                className='px-3'
                src={selectedMedia.src}
                width="300rem"
                height="350rem"
                allow="autoplay"
                type='video/mp4'
              >
                Your browser does not support the video tag.
              </iframe>
            </div>
          ) : (
            <Image
              src={isMediaObject ? selectedMedia.src : selectedMedia}
              width="370rem"
                height="350rem"
              alt={isMediaObject && selectedMedia.alt ? selectedMedia.alt : 'Selected Media'}
              fluid
            />
          )}
        </Col>
      </Row>
    </Container>

                <Row xs={6} md={6}>
                  {productMedia.map((media, index) => (
                    <div
                      key={index}
                      className={`media-thumbnail mb-2 ${
                        selectedMedia === media ? 'selected' : ''
                      }`}
                      onClick={() => handleMediaClick(media)}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                      style={{ cursor: isHovered ? 'pointer' : 'default' }}
                    >
                      {media.type === 'video' ? <i style={{fontSize: 3+'rem'}} class= 'image.thumbnail' className='fas fa-solid fa-play py-3'> </i> : <Image src={media.src} thumbnail />}
                    </div>
                  ))}
                </Row>
              </Col>

              <Col md={3} style={{ marginLeft: 'auto' }}>
      <Card>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <Row>
              <Col>Price:</Col>
              <Col>
                <strong>${product.price}</strong>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            <Row>
              <Col>Shipping Cost:</Col>
              <Col>
                <h5>Free</h5>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            <Row>
              <Col>Shipping Info:</Col>
              <Col>
                <h5>{product.shippingMethod}</h5>
              </Col>
            </Row>
          </ListGroup.Item>

          <ListGroup.Item>
            <Row>
              <Col>Status:</Col>
              <Col>
                {product.countInStock > 0 ? 'In Stock' : 'Out Of Stock'}
              </Col>
            </Row>
          </ListGroup.Item>

          {product.countInStock > 0 && (
            <ListGroup.Item>
              <Row>
                <Col>Qty</Col>
                <Col>
                  <Form.Control
                    as="select"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                  >
                    {[...Array(product.countInStock).keys()].map((x) => (
                      <option key={x + 1} value={x + 1}>
                        {x + 1}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </ListGroup.Item>
          )}

          {product.colorsVariants && product.colorsVariants.length > 0 && (
            <ListGroup.Item>
              <Row>
                <Col>Color/Variants</Col>
                <Col>
                  <Form.Control
                    as="select"
                    value={selectedColorsVariants}
                    onChange={(e) => {setSelectedColorsVariants(e.target.value); handleSelectColorVariant(e.target.value);}}
                    onClick={(e) => {handleSelectColorVariant(e.target.value); setColorsVariantClicked(true);}}
                  >
                    {product.colorsVariants.map((color) => (
                      <option key={color._id} value={color.name}>
                        {color.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </ListGroup.Item>
          )}

          <ListGroup.Item>
            <Button
              onClick={addToCartHandler}
              className="btn-block"
              style={{width: 100+"%" }}
              type="button"
              disabled={product.countInStock === 0}
            >
              Add To Cart
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </Col>

              <Col md={6}>
                <ListGroup variant='flush'>
                  <ListGroup.Item>
                    <h3>{product.name}</h3>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Rating value={product.rating} text={`${product.numReviews} reviews`} />
                  </ListGroup.Item>
                  <ListGroup.Item>Price: ${product.price}</ListGroup.Item>
                  <ListGroup.Item>
                    Description: <Editor editorState={descriptionState} readOnly={true} />
                  </ListGroup.Item>

                  {/* Additional details go here */}
                  <ListGroup.Item>
                    <Row>
                      <Col>Brand:</Col>
                      <Col>{product.brand}</Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>Category:</Col>
                      <Col>{product.category}</Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>

            <Row>
              <Col md={9}>
                <h2>Reviews</h2>
                {product.reviews.length === 0 && <Message>No Reviews</Message>}
                <ListGroup variant='flush'>
                  {product.reviews.map((review) => (
                    <ListGroup.Item key={review._id}>
                      <strong>{review.name}</strong>
                      <Rating value={review.rating} />
                      <p>{review.createdAt.substring(0, 10)}</p>
                      <p>{review.comment}</p>
                    </ListGroup.Item>
                  ))}
                  <ListGroup.Item>
                    <h2>Write a Customer Review</h2>
                    {successProductReview && (
                          <Message variant='success'>
                            Review submitted successfully
                          </Message>
                        )}
                        {loadingProductReview && <Loader />}
                        {errorProductReview && (
                          <Message variant='danger'>{errorProductReview}</Message>
                        )}
                    {userInfo ? (
                      <Form onSubmit={submitHandler}>
                        <Form.Group controlId='rating'>
                          <Form.Label>Rating</Form.Label>
                          <Form.Control
                            as='select'
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                          >
                            <option value=''>Select...</option>
                            <option value='1'>1 - Poor</option>
                            <option value='2'>2 - Fair</option>
                            <option value='3'>3 - Good</option>
                            <option value='4'>4 - Very Good</option>
                            <option value='5'>5 - Excellent</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId='comment'>
                          <Form.Label>Comment</Form.Label>
                          <Form.Control
                            as='textarea'
                            row='3'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                        <Button
                          disabled={loadingProductReview}
                          type='submit'
                          variant='primary'
                        >
                          Submit
                        </Button>
                      </Form>
                    ) : (
                      <Message>
                        Please <Link to='/login'>sign in</Link> to write a review{' '}
                      </Message>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </div>
         
        </>
      )}


    </>
  ) : null;
};

export default ProductScreen;
// {
//   "_id": "668740cde2fd5f108e05bb0b",
//   "src": 
// } and "/uploads\\files-1720139981022.jpg"