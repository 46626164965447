import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import { Modal, Form, Alert, Row, Col, ListGroup, Image, Card, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { sendTrackingNumbers } from '../actions/trackingActions'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { sendShipmentTracking } from '../actions/trackingActions'
import ShipmentStates from '../components/ShipmentStates'
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
  shipOrder,
} from '../actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_SHIP_RESET,
} from '../constants/orderConstants'
import { indexOf } from 'lodash'

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id
 
  const [sdkReady, setSdkReady] = useState(false)

  const dispatch = useDispatch()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails
  
  const shipmentTracking = useSelector((state) => state.shipmentTracking)
  const { success: historyData, loading: loading2, error: error2, data: payloadship} = shipmentTracking

  const hD = historyData

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay, data: payload } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver
  
  const orderShip = useSelector((state) => state.orderShip)
  const { loading: loadingShip, success: successShip } = orderShip

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  
  const [res, setRes] = useState([0] || '');
  const [trackingNumbers, setTrackingNumbers] = useState([] || '');
  const [bigNumber, setBigNumber] = useState([] || '');

  const [showShipmentModal , setShowShipmentModal ] = useState(false);

  const handleCloseShipmentModal = () => setShowShipmentModal (false);
  // const handleShowShipmentModal = () => setShowShipmentModal (true);

 
 
  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2)
    }
// console.log(order.trackingNumbers)

// console.log( trackingNumbers +'state')
    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
      

    )
        //  trackone = order.trackingNumbers

        //  console.log(trackingNumbers)
    
  }


  useEffect(() => {
    
    if (!userInfo) {
      history.push('/login')
    }
const trackingTrue = true;
    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get('/api/config/paypal')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`
      script.async = true
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (!order || successPay || successDeliver || successShip
      || order._id !== orderId
      ) {
        
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch({ type: ORDER_SHIP_RESET })
      dispatch(getOrderDetails(orderId))
      
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript()
      } else {
        setSdkReady(true)
      }

    }
  }, [dispatch, orderId, successPay, successDeliver, successShip, order, history, userInfo])
  
  useEffect(() => {
    if (!loading && order) {
      setTrackingNumbers(order.trackingNumbers)
      setBigNumber(order.trackingNumbers);

      console.log( payloadship +'state')
    }
  }, [loading, order]);

  useEffect(() => {
    if (payloadship) {

        setRes(payloadship);
              
    }
  }, [payloadship]);

  const successPaymentHandler = (paymentResult) => {
    console.log(paymentResult)
    dispatch(payOrder(orderId, paymentResult))
  }

  const deliverHandler = () => {
    dispatch(deliverOrder(order))
  }
  

  
  const shipHandler = () => {
    setShowModal(true);
    // dispatch(shipOrder(order))
  }
 
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleTrackingNumberChange = (index, value) => {
    const newBigNumber = [...bigNumber];
    newBigNumber[index].number = value;
    setBigNumber(newBigNumber);
  };

  const handleTrackingItemChange = (trackingIndex, itemIndex, value) => {
    const newBigNumber = [...bigNumber];
    newBigNumber[trackingIndex].trackingItem[itemIndex] = value;
    setBigNumber(newBigNumber);
  };

  const addTrackingItem = (index) => {
    const newBigNumber = [...bigNumber];
    newBigNumber[index].trackingItem.push('');
    setBigNumber(newBigNumber);
  };

  const removeTrackingItem = (trackingIndex, itemIndex) => {
    const newBigNumber = [...bigNumber];
    newBigNumber[trackingIndex].trackingItem.splice(itemIndex, 1);
    setBigNumber(newBigNumber);
  };

  const addNewTrackingNumber = () => {
    const newTrackingNumber = {
      trackingItem: [''],
      number: '',
      // _id: new Date().getTime().toString(), // Generating a unique ID
      
      
    };
    setBigNumber([...bigNumber, newTrackingNumber]);
  };

  const removeTrackingNumber = (index) => {
    const newBigNumber = [...bigNumber];
    newBigNumber.splice(index, 1);
    setBigNumber(newBigNumber);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Updated Tracking Numbers:', bigNumber);
  //   handleCloseModal();
  // };

  // const addTrackingNumbers = () => {
  //   setTrackingNumbers([...trackingNumbers, {number: ''}]);
  // };
  
  // const handleTrackingNumbersChange = (index, key, value) => {
  //   const updatedTrackingNumbers = [...trackingNumbers];
  //   updatedTrackingNumbers[index][key] = value;
  //   setTrackingNumbers(updatedTrackingNumbers);
  // };

 

  // const removeTrackingNumbers = (index) => {
  //   const updatedTrackingNumbers = trackingNumbers.filter((_, i) => i !== index);
  //   setTrackingNumbers(updatedTrackingNumbers);
  // };

  const handleCancel = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleConfirmClose = () => {
    setShowAlert(false);
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    const email = order.user.email
    e.preventDefault();
    // Handle form submission logic here
    dispatch(sendTrackingNumbers(orderId, bigNumber, email));
    console.log('Form submitted:', bigNumber );
    setShowModal(false);
  };

  
  const handleLog = (item) => {
    
const trackingId = item.number
const destinationCountry = order.shippingAddress.country 
const language = 'en'
const zipcode = order.shippingAddress.postalCode



    dispatch(sendShipmentTracking(trackingId, destinationCountry, language, zipcode))
    console.log(trackingId, destinationCountry, language, zipcode)

    
setShowShipmentModal(true)

    
  };


  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>

      <h1>Order {order._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong> {order.user.firstName} {order.user.lastName}
              </p>
              <p>
                <strong>Email: </strong>{' '}
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              <p>
                <strong>Address:</strong>
                {order.shippingAddress.address1}, {order.shippingAddress.address2}, {order.shippingAddress.city}, {order.shippingAddress.state}{' '}
                {order.shippingAddress.postalCode},{' '}
                {order.shippingAddress.country}
              </p>
              
              {order.isShipped ? (
                <Message variant='success'>
                  Shipped on {order.shippedAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Shipped</Message>
              )}
              
              {order.isDelivered ? (
                <Message variant='success'>
                  Delivered on {order.deliveredAt}
                </Message>
              ) : (
                <Message variant='danger'>Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant='success'>Paid on {order.paidAt}</Message>
              ) : (
                <Message variant='danger'>Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Tax</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>${order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
              
              {loadingShip && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isShipped && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={shipHandler}
                    >
                      Mark As Shipped
                    </Button>
                  </ListGroup.Item>
                )}

              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                order.isShipped &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type='button'
                      className='btn btn-block'
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
                
            </ListGroup>
          </Card>
        </Col>
      </Row>


      <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Tracking Numbers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group controlId='colorVariants' className='pt-6'>
        <Form onSubmit={handleSubmit}>
            {bigNumber.map((tracking, trackingIndex) => (
              <div key={tracking._id}>
                <Form.Group controlId={`trackingNumber-${trackingIndex}`}>
                  <Form.Label>Tracking Number</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        value={tracking.number}
                        onChange={(e) =>
                          handleTrackingNumberChange(trackingIndex, e.target.value)
                        }
                      />
                    </Col>
                    <Col md="auto">
                      <Button
                        variant="danger"
                        onClick={() => removeTrackingNumber(trackingIndex)}
                      >
                        Remove Tracking Number
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
                <ListGroup>
                {bigNumber[trackingIndex].trackingItem.map((item, itemIndex) => (
                    <ListGroup.Item key={itemIndex}>
                      <Row>
                        <Col>
                          <Form.Control
                            type="text"
                            value={item}
                            onChange={(e) =>
                              handleTrackingItemChange(
                                trackingIndex,
                                itemIndex,
                                e.target.value
                              )
                            }
                          />
                        </Col>
                        <Col md="auto">
                          <Button
                            variant="danger"
                            onClick={() =>
                              removeTrackingItem(trackingIndex, itemIndex)
                            }
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <Button
                  variant="secondary"
                  onClick={() => addTrackingItem(trackingIndex)}
                >
                  Add Item
                </Button>
                <hr />
              </div>
            ))}
            <Button variant="secondary" onClick={addNewTrackingNumber}>
              Add New Tracking Number
            </Button>
            <hr />
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
          </Form.Group>
        </Modal.Body>
      </Modal>
      {/* <Modal show={showModal} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Tracking Numbers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="trackingNumbers">
              <Form.Label>Upload Tracking Numbers</Form.Label>
              <ListGroup>
                
                {order.trackingNumbers.map((number, index) => (
              
                  <Card key={index} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter tracking number"
                      value={number.number}
                      onChange={(e) => handleTrackingNumbersChange(index, 'numbers', e.target.value)}
                      className="mb-2"
                    />
                    <Button
                      variant="danger"
                      onClick={() => removeTrackingNumbers(index)}
                      className="mt-2 pt-2"
                    >
                      Remove
                    </Button>
                  </Card>
                ))}
                
              </ListGroup>
              
              <div className="pt-2">
                <Button variant="primary" onClick={addTrackingNumbers} className="mt-2 pt-2">
                  Add Tracking Number
                </Button>
              </div>
              {console.log(order.trackingNumbers)}
            </Form.Group>
            <Button variant="secondary" onClick={handleCancel} className="mt-2">
              Cancel
            </Button>
            <Button type="submit" variant="primary" className="mt-2">
              Submit
            </Button>
          </Form> */}
          {/* <Alert variant='warning' show={showAlert} onClose={handleCloseAlert} >
          <Alert.Heading>Are you sure you want to close?</Alert.Heading>
          <div className="d-flex justify-content-end" py-3>
            <Button onClick={handleConfirmClose} variant='danger' className='me-2'>
              Yes
            </Button>
            <Button onClick={handleCloseAlert} variant='secondary'>
              No
            </Button>
          </div>
        </Alert> */}
        {/* </Modal.Body>
      </Modal> */}

      <Modal show={showAlert} onHide={handleCloseAlert}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Close</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to close?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleConfirmClose}>
            Yes
          </Button>
          <Button variant='secondary' onClick={handleCloseAlert}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

              <ListGroup></ListGroup>
              <ListGroup>
              { trackingNumbers.map((trackingNumber, trackingIndex) => (
                  <ListGroup.Item key={trackingIndex} className="mb-3">

                  <Card>

                  <ListGroup>
                  <ListGroup.Item> <h1>Tracking Number:{trackingNumber.number}</h1> </ListGroup.Item>
                  {/* <ListGroup.Item><Button variant="primary" onClick={handleLog(trackingNumber.number)} className="mt-2 pt-2">
                  Add Tracking Number
                </Button></ListGroup.Item> */}
                  
                  </ListGroup>
                   
                  </Card>
                 
                {/* <ListGroup>
                    {trackingNumbers.map((item, itemIndex) => (
                        <ListGroup.Item key={itemIndex} className="mb-3">
                          <Card><h1>{item.number}
                          <Button
                            variant="danger"
                            onClick={() => removeTrackingItem(trackingIndex, itemIndex)}
                            className="mt-2 pt-2"
                          >
                            Remove Item
                          </Button>
                          <div className='pt-2'><Button variant="primary" onClick={handleLog(item.number)} className="mt-2 pt-2">
                  Add Tracking Number
                </Button></div></h1></Card>
    
                         
                        </ListGroup.Item>
                      ))}
                    
                    </ListGroup> */}
           
                    
                    <ListGroup>
                    {trackingNumbers[trackingIndex].trackingItem.map((item, itemIndex) => (
                      
                        <ListGroup.Item key={itemIndex} className="mb-3">
                          <Card>{<h5>Shippent Item:</h5>}{item}</Card>
    
                          {/* <Button
                            variant="danger"
                            onClick={() => removeTrackingItem(trackingIndex, itemIndex)}
                            className="mt-2 pt-2"
                          >
                            Remove Item
                          </Button> */}
                        </ListGroup.Item>
                      ))}
                    
                    </ListGroup>
                  
                    <Button
                      variant="primary"
                      onClick={() => handleLog(trackingNumber)}
                      className="mt-2 pt-2"
                    >
                      TRACK SHIPMENT
                    </Button>
                    
                   
                  </ListGroup.Item>
                ))}<div className="pt-2">
                
              </div>
              </ListGroup>
              
           
    

<div>  <Modal show={showShipmentModal } onHide={handleCloseShipmentModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Shipment Tracking History</Modal.Title>
        </Modal.Header>

        <Modal.Body>
       

        {loading2 ? (
    <Loader />
  ) : error2 ? (
    <Message variant='danger'>{error}</Message>
  ) : res ? (   <div>
    <h2>Movement</h2>
    <ul>


      {res.map((state, index) => (
        // <div><li key={rindexindex}>
        //    
        //    <br></br >
        //  </li>
        //     </div>

  
  <li key={index}>
    {/* state.map((state, index) => ( */}
      <p>{state} </p>

    {/* )) */}
    <br></br >
  </li>
  
    
        
      ))}
    </ul>
  </div>) : (

    
    <h1>try again</h1>
  ) }


{/* {payloadship.map} */}

  
    {/* <div>
      <h2>Shipment States</h2>
      <ul>
        {[res].map((state, index) => (
          <li key={index}>
            <p><strong>Date:</strong> {state[index]}</p>
          </li>
        ))}
      </ul>
    </div>
   */}


        {/* {payloadship && <Message variant='success'>{[payloadship]}</Message>} */}
        {/* <ShipmentStates shipments={historyData} /> */}
        {/* {console.log(payloadship.shipments[0].states)} */}
        {/* {console.log(historyData.origin)} */}
         {/* hi {loading2 ? ( <Loader /> ) : error2 ? (<Message variant='danger'>{error}</Message>) : ( {historyData})} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseShipmentModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal></div>
          

{/* <Form onSubmit={handleSubmit}>
            <Form.Group controlId="trackingNumbers">
              <Form.Label>Upload Tracking Numbers</Form.Label>
              <ListGroup>
                {order.trackingNumbers.map((number, index) => (
              
                  <Card key={index} className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Enter tracking number"
                      value={number.number}
                      onChange={(e) => handleTrackingNumbersChange(index, e.target.value)}
                      className="mb-2"
                    />
                    <Button
                      variant="danger"
                      onClick={() => removeTrackingNumbers(index)}
                      className="mt-2 pt-2"
                    >
                      Remove
                    </Button>
                  </Card>
                ))}
                
              </ListGroup>
              
              <div className="pt-2">
                <Button variant="primary" onClick={addTrackingNumbers} className="mt-2 pt-2">
                  Add Tracking Number
                </Button>
              </div>
              {console.log(order.trackingNumbers)}
            </Form.Group>
            <Button variant="secondary" onClick={handleCancel} className="mt-2">
              Cancel
            </Button>
            <Button type="submit" variant="primary" className="mt-2">
              Submit
            </Button>
          </Form> */}

        
   
    </>
  )
}

export default OrderScreen
