import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ListGroup, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, getMessages } from '../actions/supportMessageActions';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from '../components/Loader';

const App = ({ location, history }) => {
  const [username, setUsername] = useState('');
  const [filterUsername, setFilterUsername] = useState('');
  const [content, setContent] = useState('');
  const [grecaptcha, setGrecaptcha] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [recipient, setRecipient] = useState('Adminguy007');
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { messages, error } = useSelector(state => state.supportMessage || {});

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      setUsername(user.username);
      // setEmail(user.email);
    }
  }, [dispatch, history, userInfo, user]);

  useEffect(() => {
    if (error) {
      window.grecaptcha.reset();
    }
  }, [error]);

  useEffect(() => {
    const user = userInfo.username;
    dispatch(getMessages(user));
  }, [dispatch, userInfo]);

  const onChange = (value) => {
    setGrecaptcha(value);
  };

  const handleSendMessage = () => {
    setShowConfirmation(true);
  };

  const confirmSendMessage = () => {
    const sender = userInfo.username;
    dispatch(sendMessage(sender, recipient, content, grecaptcha));
    setShowConfirmation(false);
    dispatch(getMessages(userInfo.username));
    window.grecaptcha.reset();
  };

  const cancelSendMessage = () => {
    setShowConfirmation(false);
  };

  const getUniqueUsernames = () => {
    const usernames = new Set();
    messages.forEach(message => {
      usernames.add(message.sender);
      usernames.add(message.recipient);
    });
    return Array.from(usernames);
  };

  return (
    <Container>
      <Row>
        <Col>
          <h1>User</h1>
          <Form>
          <Form.Group controlId="filterUsername">
              <Form.Label>Filter by Username</Form.Label>
              <Form.Control as="select" value={filterUsername} onChange={e => { setFilterUsername(e.target.value); setRecipient(e.target.value); }}>
                <option value="">All Users</option> 
                {getUniqueUsernames().map(username => (
                  <option key={username} value={username}>{username}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="content">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} value={content} onChange={e => setContent(e.target.value)} />
            </Form.Group>
            <ReCAPTCHA
              sitekey="6LeX2aQjAAAAAD9w5qaqWgsX0LJXlJZ7ccJTucPR"
              onChange={onChange}
            />
            <Button variant="primary" onClick={handleSendMessage}>
              Send Message
            </Button>
            {error && <div className="error">{error}</div>}
          </Form>
        </Col>
        <Col>
          <h1>Administrator</h1>
          {loading ? <Loader /> :
            <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {messages &&
                messages.filter(message => {
                  return (
                    message.sender.includes(filterUsername) || message.recipient.includes(filterUsername)
                  );
                }).map((message) => (
                  <ListGroup.Item key={message._id}>
                    <strong>From: </strong>
                    {message.sender} ({message.sender})<br />
                    <strong>To: </strong>
                    {message.recipient} ({message.recipient})<br />
                    <strong>Message: </strong>
                    {message.content}<br />
                    <strong>Date/Time: </strong>
                    {message.timestamp}
                  </ListGroup.Item>
                ))}
            </ListGroup>}
        </Col>
      </Row>
      {/* Custom Confirmation Modal */}
      <Modal show={showConfirmation} onHide={cancelSendMessage}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send this message?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelSendMessage}>
            No
          </Button>
          <Button variant="primary" onClick={confirmSendMessage}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default App;

// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Form, Button, ListGroup, Modal } from 'react-bootstrap';
// import { useDispatch, useSelector } from 'react-redux';
// import { sendMessage, getMessages } from '../actions/supportMessageActions';
// import ReCAPTCHA from "react-google-recaptcha"
// import Loader from '../components/Loader'



// const App = ({ location, history }) => {

//   const [name, setName] = useState('')
//   const [username, setUserame] = useState('')
  
//   const dispatch = useDispatch();
//   const userDetails = useSelector((state) => state.userDetails)
//   const { loading, user } = userDetails
//   const userLogin = useSelector((state) => state.userLogin)
//   const { userInfo } = userLogin
//   const [showConfirmation, setShowConfirmation] = useState(false);

//   const { messages, error } = useSelector(state => state.supportMessage || {});
//   const [recipient, setRecipient] = useState('Adminguy007');
//   const [content, setContent] = useState(''); 
//   const [semail, setEmail] = useState('');
//   const [grecaptcha, setGrecaptcha] = useState('')
//   function onChange(value) {
//     console.log('Captcha value:', value)
//     setGrecaptcha(value)
//   }
 
  
//   useEffect(() => {
//     if (!userInfo) {
//       history.push('/login')
//     } else {
//         setUserame(user.username)
//         setEmail(user.email)
//       }
//   }, [dispatch, history, userInfo, user])

//   useEffect(() => {
//     if (error) {
//       window.grecaptcha.reset()
//     }
//   }, [error])

  

//   const handleSendMessage = () => {
//     setShowConfirmation(true);
//   };

//   const confirmSendMessage = () => {
//     // Assuming 'user' is the current user. You can replace it with the actual user details.
//     const sender = userInfo.username;
//     dispatch(sendMessage(sender, recipient, content, grecaptcha));
//     setShowConfirmation(false);
//     dispatch(getMessages(userInfo.username))
//     window.grecaptcha.reset()
//     dispatch(getMessages(userInfo.username))
//   };

//   const cancelSendMessage = () => {
//     setShowConfirmation(false);
//     // You can add additional logic if needed when the user cancels
//   }
// useEffect(() => {
//     // Assuming 'user' is the current user. You can replace it with the actual user details.
//     const user = userInfo.username;
//     console.log(user+"here")
//     dispatch(getMessages(user));
//   }, [dispatch, user]);
//   return (
//     <Container>
//       <Row>
//         <Col>
//           <h1>User</h1>
//           <Form>
//             {/* <Form.Group controlId="recipient">
//               <Form.Label>Administrator Name</Form.Label>
//               <Form.Control type="text" value={recipient} onChange={e => setRecipient(e.target.value)} />
//             </Form.Group> */}
//             <Form.Group controlId="content">
//               <Form.Label>Message</Form.Label>
//               <Form.Control as="textarea" rows={3} value={content} onChange={e => setContent(e.target.value)} />
//             </Form.Group>
//             <ReCAPTCHA controlId='grecaptcha'
//    sitekey="6LeX2aQjAAAAAD9w5qaqWgsX0LJXlJZ7ccJTucPR"
//     onChange={onChange}
//   />
//             <Button variant="primary" onClick={handleSendMessage}>
//               Send Message
//             </Button>
//             {error && <div className="error">{error}</div>}
//           </Form>
//         </Col>
//         <Col>
//           <h1>Administrator</h1>
// {loading ? <Loader /> : <ListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>

          
// {messages &&
//   messages.map((message) => (
//     <ListGroup.Item key={message._id}>
//       <strong>From: </strong>
//       {message.sender} ({message.sender})<br />
//       <strong>To: </strong>
//       {message.recipient} ({message.recipient})<br />
//       <strong>Message: </strong>
//       {message.content}<br />
//       <strong>Date/Time: </strong>
//       {message.timestamp}
//     </ListGroup.Item>
//         ))}
//       </ListGroup>}
          
//         </Col>
//       </Row>
//       {/* Custom Confirmation Modal */}
//       <Modal show={showConfirmation} onHide={cancelSendMessage}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirmation</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Are you sure you want to send this message?
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={cancelSendMessage}>
//             No
//           </Button>
//           <Button variant="primary" onClick={confirmSendMessage}>
//             Yes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default App;
